import classNames from "classnames";
import type {
  GetServerSideProps,
  InferGetServerSidePropsType,
  NextPage
} from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import { useState, useEffect } from 'react';
import { useUpdateEffect } from "react-use";
import { v4 } from "uuid";
import Advertisement from "../components/Advertisement";
import { BreakingTeaser } from "../components/BreakingTeaser/types";
import Container from "../components/Container";
import PrimaryGrid from "../components/Grid/PrimaryGrid";
import SecondaryGrid from "../components/Grid/SecondaryGrid";
import { Media } from "../components/Media";
import Navigation from "../components/Navigation";
import MainFeedDesktop from "../components/pages/index/MainFeedDesktop";
import MainFeedMobile from "../components/pages/index/MainFeedMobile";
import SecondaryFeed from "../components/pages/index/SecondaryFeed";
import StickySidebar from "../components/StickySidebar";
import AdsIds from "../data/ads";
import { getLeagueMenu } from "../data/leagues";
import TransferWindowIds from '../data/transferWindow';
import { listByIds } from "../services/api/ads";
import { getLeagueNews } from "../services/api/league";
import {
  getMostViewedNews,
  getNewsList,
  getNewsOne,
  getNewsStart
} from "../services/api/news";
import {
  listTransferWindowById
} from "../services/api/transferWindow";
import { API } from "../services/api/types";
import { AdsContext } from "../utils/AdsContext";
import { getBreakingTeasers } from "../utils/breakingTeaser";
import { DeviceTypeProvider } from "../utils/deviceTypeContext";
import { createServerSidePropsError } from "../utils/errorHandling/createServerSidePropsError";
import { logError } from "../utils/errorHandling/logError";
import { DeviceType, getDeviceType } from "../utils/getDeviceType";
import useCanonicalUrl from '../hooks/useCanonicalUrl';
import VideoPlayer from '../components/VideoPlayer';
import SectionHeader from '../components/SectionHeader';

const last = (arr: any[]) => arr[arr.length - 1];

const getNewsFetcher = (
  type: "league" | "latest" | "start",
  leagueId?: number
) => {
  if (type === "league") return getLeagueNews(leagueId!);
  if (type === "latest") return getNewsList;

  return getNewsStart;
};

const getCategoryType = (category: FixMeLater) => {
  let type: "start" | "league" | "latest" = "start";
  if (category.data?.leagueId) type = "league";
  if (category.data?.key === "senaste") type = "latest";

  return type;
};

const getNextRoute = (currentPath: string, page: number) => {
  if (currentPath.includes("?p"))
    return currentPath.replace(/\?p=\d+/, "?p=" + (page + 2));
  return currentPath + "?p=" + (page + 2);
};
const articlesPerPage = 20;

export const getServerSideProps: GetServerSideProps<{
  news?: API.Article[];
  latestNews?: API.Article[];
  mostViewedNews?: API.Article[];
  ads?: API.Ad[];
  deviceType?: DeviceType;
  breakingTeasers?: BreakingTeaser[];
  transferWindow?: API.TransferWindow;
  indexPageNewsClient?: API.Article[];
  setIndexPageNewsClient?: any;
  oneArticle?: API.Article;
}> = async (context) => {
  try {
    const [oneArticle, news, latestNews, mostViewedNews, breakingTeasers, ads, transferWindow] =
      await Promise.all([
        getNewsOne(),
        getNewsStart({ limit: 20, offset: 0 }),
        getNewsList({ limit: 5 }),
        getMostViewedNews({ limit: 5 }),
        getBreakingTeasers(),
        listByIds([
          AdsIds.panorama_1,
          AdsIds.panorama_2,
          AdsIds.tws_1,
          AdsIds.tws_2,
          AdsIds.mpu,
          AdsIds.mobile_1,
          AdsIds.mobile_2,
          AdsIds.desktop_textlink_top,
          AdsIds.desktop_textlink_1,
          AdsIds.desktop_textlink_2,
          AdsIds.desktop_textlink_3,
          AdsIds.desktop_textlink_4,
          AdsIds.mobile_textlink_top,
          AdsIds.mobile_textlink_1,
          AdsIds.mobile_textlink_2,
          AdsIds.mobile_textlink_3,
          AdsIds.mobile_textlink_4,
          AdsIds.desktop_vi,
          AdsIds.mobile_vi,
          AdsIds.desktop_native,
          AdsIds.mobile_native,
          AdsIds.dynamic_mobile,
          AdsIds.dynamic_desktop,
          AdsIds.tws_insider,
          AdsIds.full_page_desktop,
          AdsIds.full_page_mobile,
        ]),
        listTransferWindowById(TransferWindowIds.swedish)
      ]);

    return {
      props: {
        oneArticle: oneArticle.data.id ? oneArticle.data : null,
        news: news.data,
        latestNews: latestNews.data,
        mostViewedNews: mostViewedNews.data,
        ads: ads.data,
        deviceType: getDeviceType(context),
        breakingTeasers,
        transferWindow: transferWindow.data
      },
    };
  } catch (error: FixMeLater) {
    const errorId = v4();
    const isUserError =
      error.response?.status >= 400 && error.response?.status < 500;
    const response = createServerSidePropsError(
      isUserError ? 404 : 500,
      isUserError ? undefined : errorId
    );

    if (!isUserError) logError(error, errorId);

    context.res.statusCode = isUserError ? 404 : 500;

    return response;
  }
};

const IndexScreen: NextPage<
  InferGetServerSidePropsType<typeof getServerSideProps>
> = (props) => {
  const [page, setPage] = useState(0);
  const [news, setNews] = useState(props.indexPageNewsClient?.length ? props.indexPageNewsClient : props.news);
  const [category, setCategory] = useState<{
    data?: FixMeLater;
    index: number;
  }>({ data: getLeagueMenu()[0], index: 0 });
  const router = useRouter();
  const nextPageRoute = getNextRoute(router.asPath, page);

  const onChangeCategory = async () => {
    const getNews = getNewsFetcher(
      getCategoryType(category),
      category.data?.leagueId
    );
    const news = (await getNews!({ limit: 20 })).data;
    setNews(news);
  };

  useUpdateEffect(() => {
    onChangeCategory();
  }, [category]);

  useEffect(() => {
    const newestArticleId = props?.news?.[0]?.id;
    const newestClientArticleId = props?.indexPageNewsClient?.[0]?.id;

    const hasNewArticles = Boolean(newestArticleId && newestClientArticleId && newestArticleId !== newestClientArticleId);
    
    // If a new article has been added clear client news.
    if (hasNewArticles) {
      window.scrollTo(0, 0);
      setNews(props.news);
      props.setIndexPageNewsClient([])
    }
  }, [])
  

  const onClickCategory = (data: FixMeLater, index: number) => {
    setPage(0);
    setCategory({ data, index });
    props.setIndexPageNewsClient([])
  };

  const onReachEnd = async () => {
    const nextPage = page + 1;
    const getNews = getNewsFetcher(
      getCategoryType(category),
      category.data?.leagueId
    );
    const isStart = category.data?.key === "start";
    // When fetching from /start page without offset, we only get 2 articles from position = 2. Therefore
    // we need to subtract 18 from the offset to prevent skipping 18 articles from position = 2.
    let offset =
      nextPage * articlesPerPage - (isStart ? articlesPerPage - 2 : 0);

    const newArticles = (
      await getNews({
        limit: 20,
        offset,
      })
    ).data;

    const articlesFiltered = newArticles.filter(
      (article) => !news!.find((a: API.Article) => a.id === article.id)
    );

    const updatedNews = [...news!, ...articlesFiltered];

    setNews(updatedNews);
    props.setIndexPageNewsClient(updatedNews);
    setPage(nextPage);
  };

  const canonicalUrl = useCanonicalUrl();

  return (
    <AdsContext.Provider value={props.ads!}>
      <DeviceTypeProvider value={props.deviceType!}>
        <Media lessThan="md">
            <Advertisement id={AdsIds.full_page_mobile} />
          </Media>
          <Media greaterThanOrEqual="md">
            <Advertisement id={AdsIds.full_page_desktop} />
          </Media>

        <Head>
          <title>
            Senaste nytt om transfers & Silly Season - Fotbolltransfers
          </title>
          <meta
            name="description"
            content="Vi är Sveriges största sajt om transfers, övergångar och Silly season. Håll dig uppdaterad om de senaste fotbollsnyheterna om transfers med oss!"
          />
          <meta property="og:image" content={`/fb_share_ft.png`} />
          <link rel="canonical" href={canonicalUrl} />
        </Head>

        <Container>
          <Navigation />
        </Container>

        <a href={nextPageRoute} className={classNames("hidden")}>
          Nästa sida
        </a>

        <Container horizontalMargin={true}>
          <PrimaryGrid
            top={
              <>
                <Media greaterThanOrEqual={"panorama"}>
                  <Advertisement id={AdsIds.panorama_1} />
                </Media>
                <Media lessThan={"panorama"}>
                  <Advertisement id={AdsIds.mobile_1} />
                </Media>
              </>
            }
            right={
              <StickySidebar>
                <Advertisement id={AdsIds.tws_1} />
                <div className={classNames("mb-5")} />
                <Advertisement id={AdsIds.tws_2} />
              </StickySidebar>
            }
            main={
              <SecondaryGrid
                left={
                  <>
                    <Media greaterThanOrEqual={"md"}>
                      <MainFeedDesktop
                        oneArticle={props.oneArticle}
                        breakingTeasers={props.breakingTeasers!}
                        category={category}
                        onClickCategory={onClickCategory}
                        onReachEnd={onReachEnd}
                        news={news!}
                      />
                    </Media>
                    <Media lessThan={"md"}>
                      <MainFeedMobile
                        oneArticle={props.oneArticle}
                        breakingTeasers={props.breakingTeasers!}
                        category={category}
                        onClickCategory={onClickCategory}
                        onReachEnd={onReachEnd}
                        news={news!}
                        latestNews={props.latestNews!}
                        transferWindow={props.transferWindow}
                      />
                    </Media>
                  </>
                }
                right={
                  <>
                    <SectionHeader label="Video" />
                    <div className='py-5 min-h-[291px]'>
                      <VideoPlayer />
                    </div>

                    <SecondaryFeed
                      mostViewedNews={props.mostViewedNews!}
                      latestNews={props.latestNews!}
                      transferWindow={props.transferWindow}
                    />
                  </>
                }
              />
            }
          />
        </Container>
      </DeviceTypeProvider>
    </AdsContext.Provider>
  );
};

export default IndexScreen;
